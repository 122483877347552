/**
 * Created by Andrey Popov on 04.09.20
 */

var JackpotCard = function (options) {
    Card.call(this, options);

    this.feature = "jackpot";
};

JackpotCard.prototype = Object.create(Card.prototype);
JackpotCard.constructor = JackpotCard;

JackpotCard.prototype.getViewClass = function () {
    return JackpotCardView;
};

JackpotCard.prototype.getSkin = function () {
    return "jackpot";
};

JackpotCard.prototype.canPlay = function () {
    return false;
};

JackpotCard.prototype.canBeAttacked = function () {
    return false;
};

JackpotCard.prototype.autoPlay = function () {
    var extraPart = Math.ceil(200 * Math.random());
    extraPart -= extraPart % 10;
    var prize = {
        "hard": 100 + extraPart
    };
    var reward = cleverapps.user.prepareRewardByRich(prize, 50).hard;

    this.trigger("win", reward);

    Game.currentGame.counter.setTimeout(function () {
        Game.currentGame.table.removeCard(this);
        Game.currentGame.log.clear();

        Game.currentGame.addHardReward(reward);
        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.EARN.LEVEL_EXTRA_REWARD + "_" + GameBase.REWARD_HARD, { value: reward });
    }.bind(this), 1800);
};

JackpotCard.isAvailable = function () {
    if (cleverapps.flags.monetization !== cleverapps.Flags.MONETIZATION_REGULAR || cleverapps.config.editorMode) {
        return false;
    }

    var lastAppearTime = cleverapps.dataLoader.localStorage.getItem(DataLoader.TYPES.JACKPOT_LAST_APPEAR_TIME);
    if (lastAppearTime && parseInt(lastAppearTime) + JackpotCard.APPEAR_TIMEOUT > Date.now()) {
        return false;
    }

    return cleverapps.user.checkAvailable(JackpotCard.AVAILABLE);
};

JackpotCard.AVAILABLE = {
    level: 2.13
};

JackpotCard.APPEAR_TIMEOUT = cleverapps.parseInterval("1 hour");